@import url("https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif; /* Default font */
}

/* Aggiungi questa regola per applicare il font globalmente */
body {
  font-family: "Libre Caslon Text", serif;
  background-color: rgba(54, 37, 26, 1);
}

/* Classe per applicare il font Libre Caslon Text */
.font-libre {
  font-family: "Libre Caslon Text", serif;
}

h1 {
  text-align: center;
}

p {
  text-align: center;
}

.colored-background {
  background-color: rgba(54, 37, 26, 1);
}
.colored-background-light {
  background-color: rgba(122, 94, 75, 1);
}
.stile-hover {
  background-color: rgba(157, 132, 104, 1);
}

/* Aumento della specificità del selettore */
.slick-slider .slick-dots li button:before {
  color: white;
  font-size: 8px;
  opacity: 1;
  transition: all 0.3s ease;
}

.slick-slider {
  max-width: 100%; /* Impedisce che il carosello superi la larghezza del viewport */
}

.slick-list {
  padding: 0 !important; /* Rimuove padding che potrebbe causare problemi */
}

/** Animazione sfondo in movimento */
.bkImageShop {
  background-image: url("../public/img/boscolombardia.jpg");
  background-size: 100% 100%; /* Inizia con dimensioni normali */
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  animation: pulseBackground 6s infinite ease-in-out;
}
/***/
@keyframes pulseBackground {
  0%,
  100% {
    background-size: 100% 100%;
    opacity: 0.8;
  }
  50% {
    background-size: 102% 102%; /* Leggero aumento delle dimensioni per un effetto sottile */
    opacity: 0.9;
  }
}

/**testo in foreground */
.outlined-title {
  color: #e5b713; /* Colore del testo, qui è un arancione (tailwind text-orange-300) */
  text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000; /* Ogni valore aggiunge un'ombra in una direzione diversa */
}
.outlined-text {
  color: #fdfdfd; /* Colore del testo, qui è un arancione (tailwind text-orange-300) */
  /* text-shadow: -0.5px -0.5px 0 #ece7e7, 0.5px -0.5px 0 #ffffff,
    -0.5px 0.5px 0 #ffffff, 0.5px 0.5px 0 #ffffff; Ogni valore aggiunge un'ombra in una direzione diversa */
}

/*opacity  background degli elementi truffle item
*/
/* .opacity-90-bg {
  background: #000;
  opacity: 0.5;
} */

/*
barra overflow
*/
/* Personalizza la barra di scorrimento verticale */
::-webkit-scrollbar {
  width: 10px; /* Larghezza della barra di scorrimento */
}

/* Traccia della barra di scorrimento */
::-webkit-scrollbar-track {
  background: #f1f1f169; /* Colore di sfondo della traccia */
}

/* Maniglia della barra di scorrimento */
::-webkit-scrollbar-thumb {
  background: rgba(122, 94, 75, 1); /* Colore della maniglia */
  border-radius: 5px; /* Angoli arrotondati */
}

/* Hover sulla maniglia della barra di scorrimento */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Colore della maniglia quando si passa sopra con il mouse */
}
